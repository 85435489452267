import React, { useEffect, useState } from "react";
import { matchRoutes } from "react-router";
import { Navigate, useRoutes, useLocation } from "react-router-dom";

import { useViewer } from "contexts/Viewer";
import { useSession } from "contexts/Session";
import { Route } from "Navigation";
import CookiePreferences from "components/CookiePreferences";
import CookiePolicyModal from "components/CookiePolicyModal";
import Sidebar from "components/Sidebar";
import Topbar from "components/Topbar";
import Appliance from "pages/Appliance";
import Appliances from "pages/Appliances";
import ApplianceRegister from "pages/ApplianceRegister";
import DeviceClaim from "pages/DeviceClaim";
import Client from "pages/Client";
import ClientAdd from "pages/ClientAdd";
import Clients from "pages/Clients";
import ForgotPassword from "pages/ForgotPassword";
import Role from "pages/Role";
import RoleCreate from "pages/RoleCreate";
import Roles from "pages/Roles";
import Login from "pages/Login";
import Logout from "pages/Logout";
import Register from "pages/Register";
import RegisterWithInvite from "pages/RegisterWithInvite";
import ResetPassword from "pages/ResetPassword";
import Profile from "pages/Profile";
import User from "pages/User";
import UserInvite from "pages/UserInvite";
import Users from "pages/Users";
import "./App.scss";

const RedirectToLogin = () => {
  const { setRedirectTo } = useSession();
  const location = useLocation();

  // changing the session is a side effect which may cause a re-render
  useEffect(() => {
    const match = matchRoutes(authenticatedRoutes, location);
    if (
      match &&
      match[0].route.path !== "*" &&
      match[0].route.path !== Route.logout
    ) {
      const { pathname, search } = location;
      setRedirectTo(pathname + search);
    }
  }, [location, setRedirectTo]);

  return <Navigate to={Route.login} />;
};

type RouterRule = {
  path: string;
  element: JSX.Element;
};

const publicRoutes: RouterRule[] = [
  { path: Route.login, element: <Login /> },
  { path: Route.register, element: <Register /> },
  { path: Route.registerWithInvite, element: <RegisterWithInvite /> },
  { path: Route.forgotPassword, element: <ForgotPassword /> },
  { path: Route.resetPassword, element: <ResetPassword /> },
  { path: "*", element: <RedirectToLogin /> },
];

const authenticatedRoutes: RouterRule[] = [
  { path: Route.appliances, element: <Appliances /> },
  { path: Route.appliancesNew, element: <ApplianceRegister /> },
  { path: Route.appliancesEdit, element: <Appliance /> },
  { path: Route.devicesClaim, element: <DeviceClaim /> },
  { path: Route.clients, element: <Clients /> },
  { path: Route.clientsNew, element: <ClientAdd /> },
  { path: Route.clientsEdit, element: <Client /> },
  { path: Route.users, element: <Users /> },
  { path: Route.usersInvite, element: <UserInvite /> },
  { path: Route.usersEdit, element: <User /> },
  { path: Route.roles, element: <Roles /> },
  { path: Route.rolesNew, element: <RoleCreate /> },
  { path: Route.rolesEdit, element: <Role /> },
  { path: Route.profile, element: <Profile /> },
  { path: Route.logout, element: <Logout /> },
  { path: "*", element: <Navigate to={Route.appliances} /> },
];

function App() {
  const { cookiePreferences, setCookiePreferences } = useSession();
  const { isAuthenticated } = useViewer();
  const routes = isAuthenticated ? authenticatedRoutes : publicRoutes;
  const RouterElement = useRoutes(routes);
  const [showCookiPolicy, setShowCookiePolicy] = useState(false);

  return (
    <div className="vh-100 flex-grow-1 d-flex">
      <div
        className={"app-background" + (isAuthenticated ? " authenticated" : "")}
      ></div>
      {isAuthenticated && (
        <aside className="vh-100 flex-grow-0 flex-shrink-0 d-flex flex-column overflow-scroll p-3 ps-4 pt-4">
          <Sidebar />
        </aside>
      )}
      <main className="vh-100 flex-grow-1 d-flex flex-column overflow-scroll p-3 pt-4">
        {isAuthenticated && (
          <header className="flex-grow-0 mb-3 pb-3">
            <Topbar />
          </header>
        )}
        <section className="flex-grow-1 d-flex flex-column">
          {RouterElement}
        </section>
      </main>
      {!cookiePreferences && (
        <CookiePreferences
          onSelect={setCookiePreferences}
          onShowCookiePolicy={() => setShowCookiePolicy(true)}
        />
      )}
      {showCookiPolicy && (
        <CookiePolicyModal onClose={() => setShowCookiePolicy(false)} />
      )}
    </div>
  );
}

export default App;
