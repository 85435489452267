/**
 * @generated SignedSource<<fa65f1074b7ee5821e5c3b6e8e2d7c85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Client_getAppliances_Query$variables = {};
export type Client_getAppliances_Query$data = {
  readonly appliances: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly serial: string;
    readonly device: {
      readonly lastConnection: string | null;
      readonly lastDisconnection: string | null;
      readonly online: boolean;
    };
    readonly assignee: {
      readonly id: string;
      readonly name: string;
    } | null;
  }>;
};
export type Client_getAppliances_Query = {
  variables: Client_getAppliances_Query$variables;
  response: Client_getAppliances_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serial",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastConnection",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastDisconnection",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "online",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "assignee",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Client_getAppliances_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Appliance",
        "kind": "LinkedField",
        "name": "appliances",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Client_getAppliances_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Appliance",
        "kind": "LinkedField",
        "name": "appliances",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "26371470a06fc8bac87573ffbff9b4e7",
    "id": null,
    "metadata": {},
    "name": "Client_getAppliances_Query",
    "operationKind": "query",
    "text": "query Client_getAppliances_Query {\n  appliances {\n    id\n    name\n    serial\n    device {\n      lastConnection\n      lastDisconnection\n      online\n      id\n    }\n    assignee {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd1d7f402d3f7c46946e116e7893fbbc";

export default node;
