import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import Alert from "react-bootstrap/Alert";

import type { Profile_getViewer_Query } from "api/__generated__/Profile_getViewer_Query.graphql";
import type { Profile_updateUserPreferences_Mutation } from "api/__generated__/Profile_updateUserPreferences_Mutation.graphql";
import type { Profile_upgradeToFullOrganization_Mutation } from "api/__generated__/Profile_upgradeToFullOrganization_Mutation.graphql";
import type {
  Profile_updateUserPassword_Mutation,
  UpdateUserPasswordInput,
} from "api/__generated__/Profile_updateUserPassword_Mutation.graphql";
import * as images from "assets/images";
import Can from "components/Can";
import Image from "components/Image";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import ProfileForm, { Data as ProfileData } from "components/ProfileForm";
import UpgradeToFullOrganizationModal from "components/UpgradeToFullOrganizationModal";
import UpdatePasswordModal from "components/UpdatePasswordModal";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import Tag from "components/Tag";
import { useViewer } from "contexts/Viewer";

const GET_VIEWER_QUERY = graphql`
  query Profile_getViewer_Query {
    viewer {
      id
      name
      email
      organization {
        id
        name
      }
      roles {
        id
        name
      }
      preferences {
        language
      }
    }
  }
`;

const UPDATE_USER_PREFERENCES_MUTATION = graphql`
  mutation Profile_updateUserPreferences_Mutation(
    $input: UpdateUserPreferencesInput!
  ) {
    updateUserPreferences(input: $input) {
      user {
        preferences {
          language
        }
      }
    }
  }
`;

const UPGRADE_TO_FULL_ORGANIZATION_MUTATION = graphql`
  mutation Profile_upgradeToFullOrganization_Mutation(
    $input: UpgradeToFullOrganizationInput!
  ) {
    upgradeToFullOrganization(input: $input) {
      organization {
        id
        name
        thin
      }
    }
  }
`;

const UPDATE_USER_PASSWORD_MUTATION = graphql`
  mutation Profile_updateUserPassword_Mutation(
    $input: UpdateUserPasswordInput!
  ) {
    updateUserPassword(input: $input) {
      user {
        id
      }
    }
  }
`;

type ProfileSidebarProps = {
  user?: Profile_getViewer_Query["response"]["viewer"];
};

const ProfileSidebar = ({ user }: ProfileSidebarProps) => {
  const { isThinOrganization } = useViewer();

  if (!user) {
    return (
      <Stack gap={3} className="mt-3 p-3">
        <Image fallbackSrc={images.profile} />
      </Stack>
    );
  }

  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      <div>
        <h4>{user.name}</h4>
        <p className="text-muted">{user.email}</p>
      </div>
      <Image fallbackSrc={images.profile} />
      <Can oneOf={["CAN_LIST_ROLES"]}>
        <div>
          {user.roles.map((role) => (
            <Tag className="mb-1 me-1" key={role.id}>
              {role.name}
            </Tag>
          ))}
        </div>
      </Can>
      {!isThinOrganization && (
        <>
          <hr />
          {user.organization.name}
        </>
      )}
    </Stack>
  );
};

interface ViewerContentProps {
  getViewerQuery: PreloadedQuery<Profile_getViewer_Query>;
}

const ProfileContent = ({ getViewerQuery }: ViewerContentProps) => {
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [
    showUpgradeToFullOrganizationModal,
    setShowUpgradeToFullOrganizationModal,
  ] = useState(false);
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const viewerData = usePreloadedQuery(GET_VIEWER_QUERY, getViewerQuery);
  // TODO: handle readonly roles type without mapping to mutable type
  const viewer = useMemo(
    () =>
      viewerData.viewer && {
        ...viewerData.viewer,
        organization: {
          ...viewerData.viewer.organization,
        },
        roles: viewerData.viewer.roles.map((role) => ({
          id: role.id,
          name: role.name,
        })),
        language: viewerData.viewer.preferences.language,
      },
    [viewerData.viewer]
  )!;
  const [
    updateUserPreferences,
  ] = useMutation<Profile_updateUserPreferences_Mutation>(
    UPDATE_USER_PREFERENCES_MUTATION
  );
  const [
    upgradeToFullOrganization,
    isUpgradingToFullOrganization,
  ] = useMutation<Profile_upgradeToFullOrganization_Mutation>(
    UPGRADE_TO_FULL_ORGANIZATION_MUTATION
  );
  const [
    updatePassword,
    isUpdatingPassword,
  ] = useMutation<Profile_updateUserPassword_Mutation>(
    UPDATE_USER_PASSWORD_MUTATION
  );

  const handleChange = useCallback(
    (data: ProfileData, isValid: boolean) => {
      if (data.language !== viewer.language) {
        const preferences = { language: data.language };
        updateUserPreferences({
          variables: {
            input: { preferences },
          },
          onCompleted(data, errors) {
            if (errors) {
              const errorFeedback = errors
                .map((error) => error.message)
                .join(". \n");
              setErrorFeedback(errorFeedback);
            }
          },
          onError(error) {
            setErrorFeedback(
              <FormattedMessage
                id="pages.Profile.form.loadingErrorFeedback"
                defaultMessage="Could not update the profile, please try again."
                description="Feedback for unknown loading error in the Profile page"
              />
            );
          },
          optimisticResponse: {
            updateUserPreferences: {
              user: {
                id: viewer.id,
                preferences: {
                  language: data.language,
                },
              },
            },
          },
        });
      }
    },
    [updateUserPreferences, viewer.language, viewer.id]
  );

  const handleUpgradeToFullOrganization = useCallback(
    (organizationName: string) => {
      const variables = { input: { organizationName } };
      upgradeToFullOrganization({
        variables,
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            setErrorFeedback(errorFeedback);
          }
          setShowUpgradeToFullOrganizationModal(false);
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.Profile.upgradeToFullOrganizationErrorFeedback"
              defaultMessage="Could not upgrade to full organization, please try again."
              description="Feedback for unknown error while upgrading to full organization"
            />
          );
          setShowUpgradeToFullOrganizationModal(false);
        },
      });
    },
    [upgradeToFullOrganization]
  );

  const handleUpdatePassword = useCallback(
    (input: UpdateUserPasswordInput) => {
      updatePassword({
        variables: { input },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            setErrorFeedback(errorFeedback);
          }
          setShowUpdatePasswordModal(false);
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.Profile.changePasswordErrorFeedback"
              defaultMessage="Could not change the password, please try again."
              description="Feedback for unknown error while chaning the password"
            />
          );
          setShowUpdatePasswordModal(false);
        },
      });
    },
    [updatePassword]
  );

  return (
    <>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <ProfileForm
        value={viewer}
        onChange={handleChange}
        onUpgradeToFullOrganization={() =>
          setShowUpgradeToFullOrganizationModal(true)
        }
        onUpdatePassword={() => setShowUpdatePasswordModal(true)}
        readonly
      />
      {showUpgradeToFullOrganizationModal && (
        <UpgradeToFullOrganizationModal
          defaultName={viewer.organization.name}
          onCancel={() => setShowUpgradeToFullOrganizationModal(false)}
          onConfirm={handleUpgradeToFullOrganization}
          isConfirming={isUpgradingToFullOrganization}
        />
      )}
      {showUpdatePasswordModal && (
        <UpdatePasswordModal
          onCancel={() => setShowUpdatePasswordModal(false)}
          onConfirm={handleUpdatePassword}
          isConfirming={isUpdatingPassword}
        />
      )}
      <SidebarContent>
        <ProfileSidebar user={viewer} />
      </SidebarContent>
    </>
  );
};

const Profile = () => {
  const [getViewerQuery, getViewer] = useQueryLoader<Profile_getViewer_Query>(
    GET_VIEWER_QUERY
  );

  const getData = useCallback(() => {
    getViewer({});
  }, [getViewer]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.Profile.title"
          defaultMessage="Profile Details"
          description="Title for the Profile page"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <ProfileSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <ProfileSidebar />
              </SidebarContent>
            </>
          )}
          onReset={getData}
        >
          {getViewerQuery && <ProfileContent getViewerQuery={getViewerQuery} />}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Profile;
