import React, { Suspense, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Chart from "react-apexcharts";

import type { Appliances_getAppliances_Query } from "api/__generated__/Appliances_getAppliances_Query.graphql";
import * as images from "assets/images";
import { Link, Route } from "Navigation";
import AppliancesTable from "components/AppliancesTable";
import { useCanOneOf } from "components/Can";
import Image from "components/Image";
import Page, {
  PageLoading,
  PageLoadingError,
  PageToolbar,
} from "components/Page";
import Result from "components/Result";
import ResourceCounter from "components/ResourceCounter";
import SearchBox from "components/SearchBox";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";

const GET_APPLIANCES_QUERY = graphql`
  query Appliances_getAppliances_Query {
    appliances {
      id
      name
      serial
      tags
      device {
        lastConnection
        lastDisconnection
        online
      }
      assignee {
        id
        name
      }
    }
  }
`;

type AppliancesSidebarProps = {
  appliances?: Appliances_getAppliances_Query["response"]["appliances"];
};

const AppliancesSidebar = ({ appliances = [] }: AppliancesSidebarProps) => {
  const intl = useIntl();
  const connectedAppliances = appliances.filter(
    (appliance) => appliance.device.online
  ).length;
  const disconnectedAppliances = appliances.filter(
    (appliance) => !appliance.device.online
  ).length;

  const chartOptions = {
    labels: [
      intl.formatMessage({
        id: "pages.Appliances.countChart.connectedLabel",
        defaultMessage: "Connected",
      }),
      intl.formatMessage({
        id: "pages.Appliances.countChart.disconnectedLabel",
        defaultMessage: "Disconnected",
      }),
    ],
    colors: ["var(--cp-color-connected)", "var(--cp-color-disconnected)"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
    } as const,
  };

  return (
    <Stack gap={5} className="mt-3 p-3">
      {appliances.length > 0 ? (
        <Chart
          options={chartOptions}
          type="donut"
          width="100%"
          height="200"
          series={[connectedAppliances, disconnectedAppliances]}
        />
      ) : (
        <Image src={images.devices} />
      )}
      <ResourceCounter
        resource={
          <FormattedMessage
            id="pages.Appliances.connectedAppliancesCounter.resource"
            defaultMessage="Connected {count, plural, =0 {appliances} one {appliance} other {appliances}}"
            values={{ count: connectedAppliances }}
          />
        }
        count={connectedAppliances}
      />
      <ResourceCounter
        resource={
          <FormattedMessage
            id="pages.Appliances.disconnectedAppliancesCounter.resource"
            defaultMessage="Disconnected {count, plural, =0 {appliances} one {appliance} other {appliances}}"
            values={{ count: disconnectedAppliances }}
          />
        }
        count={disconnectedAppliances}
      />
    </Stack>
  );
};

interface AppliancesContentProps {
  getAppliancesQuery: PreloadedQuery<Appliances_getAppliances_Query>;
}

const AppliancesContent = ({ getAppliancesQuery }: AppliancesContentProps) => {
  const [searchText, setSearchText] = useState("");
  const appliancesData = usePreloadedQuery(
    GET_APPLIANCES_QUERY,
    getAppliancesQuery
  );

  // TODO: handle readonly type without mapping to mutable type
  const appliances = useMemo(
    () =>
      appliancesData.appliances.map((appliance) => ({
        id: appliance.id,
        name: appliance.name,
        serial: appliance.serial,
        tags: appliance.tags.slice(),
        assignee: appliance.assignee && {
          id: appliance.assignee.id,
          name: appliance.assignee.name,
        },
        device: {
          ...appliance.device,
        },
      })),
    [appliancesData.appliances]
  );

  const canShowClients = useCanOneOf(["CAN_LIST_CLIENTS"]);

  if (appliances.length === 0) {
    return (
      <>
        <Result.Empty
          title={
            <FormattedMessage
              id="pages.Appliances.noAppliances.title"
              defaultMessage="There are no appliances yet."
            />
          }
        >
          <Link route={Route.appliancesNew}>
            <FormattedMessage
              id="pages.Appliances.noAppliances.message"
              defaultMessage="Register an appliance"
            />
          </Link>
        </Result.Empty>
        <SidebarContent>
          <AppliancesSidebar />
        </SidebarContent>
      </>
    );
  }

  return (
    <>
      <PageToolbar>
        <SearchBox value={searchText} onChange={setSearchText} />
      </PageToolbar>
      <AppliancesTable
        data={appliances}
        hideAssignee={!canShowClients}
        searchText={searchText}
      />
      <SidebarContent>
        <AppliancesSidebar appliances={appliances} />
      </SidebarContent>
    </>
  );
};

const Appliances = () => {
  const [
    getAppliancesQuery,
    getAppliances,
  ] = useQueryLoader<Appliances_getAppliances_Query>(GET_APPLIANCES_QUERY);

  useEffect(() => {
    getAppliances({});
  }, [getAppliances]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.Appliances.title"
          defaultMessage="Appliance List"
          description="Title for the Appliances page"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <AppliancesSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <AppliancesSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => {
            getAppliances({});
          }}
        >
          {getAppliancesQuery && (
            <AppliancesContent getAppliancesQuery={getAppliancesQuery} />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Appliances;
