import React, { useCallback, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Form from "react-bootstrap/Form";

import Spinner from "components/Spinner";
import Stack from "components/Stack";
import "./DeviceClaimForm.scss";

type DeviceClaimDraft = {
  applianceName: string;
  applianceSerial: string | null;
  deviceId: string | null;
  token: string | null;
};

interface Props {
  className?: string;
  value: DeviceClaimDraft;
  onChange?: (data: DeviceClaimDraft, isValid: boolean) => void;
  appliancePartNumber?: string | null;
  readonlyApplianceSerial: boolean;
  showDeviceId: boolean;
  validDeviceId: boolean;
  showToken: boolean;
  validToken: boolean;
  hasLoadingDeviceRef: boolean;
}

const DeviceClaimForm = ({
  className = "",
  value,
  onChange,
  appliancePartNumber,
  readonlyApplianceSerial,
  showDeviceId,
  validDeviceId,
  showToken,
  validToken,
  hasLoadingDeviceRef,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const intl = useIntl();

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const target = event.target;
      const fieldValue = target.value;
      const field = target.id;
      const newValue = { ...value, [field]: fieldValue };
      const isFormValid = !!formRef.current?.checkValidity();
      onChange && onChange(newValue, isFormValid);
    },
    [value, onChange]
  );

  return (
    <Form ref={formRef} className={className} data-testid="device-claim-form">
      <Stack gap={3}>
        <Form.Group controlId="applianceName">
          <Form.Label>
            <FormattedMessage
              id="components.DeviceClaimForm.nameLabel"
              defaultMessage="Name"
              description="Label for the name field in the device claim form"
            />
          </Form.Label>
          <Form.Control
            value={value.applianceName}
            onChange={handleInputChange}
            required
            placeholder={intl.formatMessage({
              id: "components.DeviceClaimForm.namePlaceholder",
              defaultMessage: "Type in the appliance name",
              description:
                "Placeholder for the name field in the device claim form",
            })}
            name="applianceName"
            data-testid="device-claim-form-name"
          />
        </Form.Group>
        {showToken && (
          <Form.Group controlId="token">
            <Form.Label>
              <FormattedMessage
                id="components.DeviceClaimForm.tokenLabel"
                defaultMessage="Token"
                description="Label for the token field in the device claim form"
              />
            </Form.Label>
            <div className="d-flex">
              <Form.Control
                value={value.token || ""}
                onChange={handleInputChange}
                required
                isInvalid={!validToken}
                placeholder={intl.formatMessage({
                  id: "components.DeviceClaimForm.tokenPlaceholder",
                  defaultMessage: "Type in the token",
                  description:
                    "Placeholder for the token field in the device claim form",
                })}
                name="token"
                data-testid="device-claim-form-token"
              />
              {hasLoadingDeviceRef && (
                <div className="input-spinner">
                  <Spinner size="sm" />
                </div>
              )}
            </div>
          </Form.Group>
        )}
        {showDeviceId && (
          <Form.Group controlId="deviceId">
            <Form.Label>
              <FormattedMessage
                id="components.DeviceClaimForm.deviceIdLabel"
                defaultMessage="Device ID"
                description="Label for the deviceId field in the device claim form"
              />
            </Form.Label>
            <div className="d-flex">
              <Form.Control
                value={value.deviceId || ""}
                onChange={handleInputChange}
                required
                isInvalid={!validDeviceId}
                placeholder={intl.formatMessage({
                  id: "components.DeviceClaimForm.deviceIdPlaceholder",
                  defaultMessage: "Type in the Astarte device ID",
                  description:
                    "Placeholder for the deviceId field in the device claim form",
                })}
                name="deviceId"
                data-testid="device-claim-form-deviceId"
              />
              {hasLoadingDeviceRef && (
                <div className="input-spinner">
                  <Spinner size="sm" />
                </div>
              )}
            </div>
          </Form.Group>
        )}
        <Form.Group controlId="applianceSerial">
          <Form.Label>
            <FormattedMessage
              id="components.DeviceClaimForm.serialLabel"
              defaultMessage="Serial Number"
              description="Label for the serial field in the device claim form"
            />
          </Form.Label>
          <Form.Control
            value={value.applianceSerial || ""}
            onChange={handleInputChange}
            required
            readOnly={readonlyApplianceSerial}
            plaintext={readonlyApplianceSerial}
            placeholder={
              readonlyApplianceSerial
                ? "-"
                : intl.formatMessage({
                    id: "components.DeviceClaimForm.serialPlaceholder",
                    defaultMessage: "Type in the appliance serial",
                    description:
                      "Placeholder for the serial number field in the device claim form",
                  })
            }
            name="applianceSerial"
            data-testid="device-claim-form-serial"
          />
        </Form.Group>
        {appliancePartNumber && (
          <Form.Group controlId="appliancePartNumber">
            <Form.Label>
              <FormattedMessage
                id="components.DeviceClaimForm.partNumberLabel"
                defaultMessage="Part Number"
                description="Label for the part number field in the device claim form"
              />
            </Form.Label>
            <Form.Control
              value={appliancePartNumber}
              required
              readOnly
              plaintext
              name="appliancePartNumber"
              data-testid="device-claim-form-serial"
            />
          </Form.Group>
        )}
      </Stack>
    </Form>
  );
};

export type { DeviceClaimDraft };

export default DeviceClaimForm;
