import { createPortal } from "react-dom";
import Card from "react-bootstrap/Card";

import * as images from "assets/images";
import { useTenantConfig } from "contexts/TenantConfig";
import "./Sidebar.scss";

const Sidebar = () => {
  const tenantConfig = useTenantConfig();
  const brandImg = tenantConfig.design.logo || images.brand;

  return (
    <Card className="flex-grow-1 sidebar border-0 shadow d-flex flex-column">
      <img
        alt="Logo"
        src={brandImg}
        className="sidebar-brand mw-100 mx-auto my-3 py-2"
      />
      <div id="sidebar-content" className="flex-grow-1" />
    </Card>
  );
};

const SidebarContent = ({ children }: { children: React.ReactNode }) => {
  const node = document.getElementById("sidebar-content");
  if (!node) {
    return null;
  }
  return createPortal(children, node);
};

export { SidebarContent };

export default Sidebar;
