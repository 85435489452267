import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useFragment,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  useSubscription,
  PreloadedQuery,
} from "react-relay/hooks";
import { FormattedMessage, useIntl } from "react-intl";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import _ from "lodash";

import * as images from "assets/images";
import type { Appliance_provisioningStatus$key } from "api/__generated__/Appliance_provisioningStatus.graphql";
import type { Appliance_getAppliance_Query } from "api/__generated__/Appliance_getAppliance_Query.graphql";
import type { Appliance_updateAppliance_Mutation } from "api/__generated__/Appliance_updateAppliance_Mutation.graphql";
import { Link, Route } from "Navigation";
import { useCanOneOf } from "components/Can";
import ConnectionStatus from "components/ConnectionStatus";
import Center from "components/Center";
import ClientAssignee from "components/ClientAssignee";
import Image from "components/Image";
import MultiSelect from "components/MultiSelect";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import Result from "components/Result";
import RemoteApp, { isSupportedApp } from "components/RemoteApp";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import Tabs, { Tab } from "components/Tabs";
import Tag from "components/Tag";

const APPLIANCE_PROVISIONING_STATUS_FRAGMENT = graphql`
  fragment Appliance_provisioningStatus on Device {
    provisioningStatus {
      osCodeId
      osVersion
    }
  }
`;

const APPLIANCE_APPLIANCEUPDATED_SUBSCRIPTION = graphql`
  subscription Appliance_applianceUpdated_Subscription($ids: [ID!]!) {
    applianceUpdated(ids: $ids) {
      id
      name
      serial
      tags
      assignee {
        id
        name
      }
      device {
        online
      }
    }
  }
`;

interface ApplianceProvisioningStatusProps {
  deviceRef: Appliance_provisioningStatus$key;
}

const ApplianceProvisioningStatus = ({
  deviceRef,
}: ApplianceProvisioningStatusProps) => {
  const { provisioningStatus } = useFragment(
    APPLIANCE_PROVISIONING_STATUS_FRAGMENT,
    deviceRef
  );
  if (!provisioningStatus) {
    return null;
  }
  return (
    <Stack gap={3}>
      {provisioningStatus.osCodeId != null && (
        <Form.Group controlId="form-appliance-os">
          <Form.Label>
            <FormattedMessage
              id="Appliance.operativeSystem"
              defaultMessage="OS"
              description="Label for the appliance operative system"
            />
          </Form.Label>
          <Form.Control
            type="text"
            value={provisioningStatus.osCodeId}
            readOnly
            plaintext
          />
        </Form.Group>
      )}
      {provisioningStatus.osVersion != null && (
        <Form.Group controlId="form-appliance-os-version">
          <Form.Label>
            <FormattedMessage
              id="Appliance.operativeSystemVersion"
              defaultMessage="OS version"
              description="Label for the appliance operative system version"
            />
          </Form.Label>
          <Form.Control
            type="text"
            value={provisioningStatus.osVersion}
            readOnly
            plaintext
          />
        </Form.Group>
      )}
    </Stack>
  );
};

// TODO: Use specific appliance query of fragment instead of filtering the appliance list
const GET_APPLIANCE_QUERY = graphql`
  query Appliance_getAppliance_Query($id: ID!) {
    appliance(id: $id) {
      id
      name
      serial
      tags
      device {
        deviceId
        realm
        baseApiUrl
        online
        ...Appliance_provisioningStatus
        availableApplications {
          id
          protocol
          displayName
          authToken
          sourceUrl
        }
      }
      assignee {
        id
        name
      }
      ...ClientAssignee_applianceAssignee
    }
  }
`;

const UPDATE_APPLIANCE_MUTATION = graphql`
  mutation Appliance_updateAppliance_Mutation($input: UpdateApplianceInput!) {
    updateAppliance(input: $input) {
      appliance {
        id
        name
        tags
      }
    }
  }
`;

type ApplianceSidebarProps = {
  appliance?: Appliance_getAppliance_Query["response"]["appliance"];
};

const ApplianceSidebar = ({ appliance }: ApplianceSidebarProps) => {
  if (!appliance) {
    return (
      <Stack gap={3} className="mt-3 p-3">
        <Image src={images.devices} />
      </Stack>
    );
  }
  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      <div>
        <h4>{appliance.name}</h4>
        <p className="text-muted">{appliance.serial}</p>
      </div>
      <Image fallbackSrc={images.devices} />
      <Center>
        <ConnectionStatus connected={appliance.device.online} />
      </Center>
      <div>
        {appliance.tags.map((tag) => (
          <Tag className="mb-1 me-1" key={tag}>
            {tag}
          </Tag>
        ))}
      </div>
      {appliance.assignee && (
        <>
          <hr />
          {appliance.assignee.name}
        </>
      )}
    </Stack>
  );
};

interface ApplianceContentProps {
  getApplianceQuery: PreloadedQuery<Appliance_getAppliance_Query>;
}

const ApplianceContent = ({ getApplianceQuery }: ApplianceContentProps) => {
  const { applianceId = "" } = useParams();
  const intl = useIntl();
  const canEditAppliances = useCanOneOf(["CAN_EDIT_APPLIANCES"]);
  const applianceData = usePreloadedQuery(
    GET_APPLIANCE_QUERY,
    getApplianceQuery
  );
  const [updateAppliance] = useMutation<Appliance_updateAppliance_Mutation>(
    UPDATE_APPLIANCE_MUTATION
  );

  // TODO: handle readonly type without mapping to mutable type
  const appliance = useMemo(
    () =>
      applianceData.appliance && {
        ...applianceData.appliance,
        tags: applianceData.appliance.tags.slice(),
        device: {
          ...applianceData.appliance.device,
        },
      },
    [applianceData.appliance]
  );

  const config = useMemo(
    () => ({
      variables: { ids: [appliance?.id] },
      subscription: APPLIANCE_APPLIANCEUPDATED_SUBSCRIPTION,
    }),
    [appliance?.id]
  );
  useSubscription(config);

  const externalApps = useMemo(
    () =>
      appliance?.device.availableApplications
        ? appliance.device.availableApplications
            .map((app) => ({
              id: app.id,
              protocol: app.protocol,
              displayName: app.displayName,
              sourceUrl: new URL(app.sourceUrl),
              props: {
                astarteUrl: new URL(appliance.device.baseApiUrl),
                realm: appliance.device.realm,
                deviceId: appliance.device.deviceId,
                token: app.authToken,
              },
            }))
            .filter(isSupportedApp)
        : [],
    [appliance]
  );

  const [applianceDraft, setApplianceDraft] = useState(
    _.pick(appliance, ["name", "tags"])
  );
  const [
    updateErrorFeedback,
    setUpdateErrorFeedback,
  ] = useState<React.ReactNode>(null);

  const handleUpdateAppliance = useMemo(
    () =>
      _.debounce(
        (
          draft: typeof applianceDraft,
          applianceChanges: Partial<typeof applianceDraft>
        ) => {
          updateAppliance({
            variables: { input: { applianceId, ...applianceChanges } },
            onCompleted(data, errors) {
              if (errors) {
                setApplianceDraft(draft);
                const updateErrorFeedback = errors
                  .map((error) => error.message)
                  .join(". \n");
                return setUpdateErrorFeedback(updateErrorFeedback);
              }
            },
            onError(error) {
              setApplianceDraft(draft);
              setUpdateErrorFeedback(
                <FormattedMessage
                  id="pages.Appliance.updateApplianceErrorFeedback"
                  defaultMessage="Could not update the appliance, please try again."
                  description="Feedback for unknown error while updating an appliance"
                />
              );
            },
          });
        },
        500,
        { leading: true }
      ),
    [updateAppliance, applianceId]
  );

  const handleApplianceChange = useCallback(
    (applianceChanges: Partial<typeof applianceDraft>) => {
      setApplianceDraft((draft) => ({ ...draft, ...applianceChanges }));
      handleUpdateAppliance(applianceDraft, applianceChanges);
    },
    [handleUpdateAppliance, applianceDraft]
  );

  const handleApplianceTagsChange = useCallback(
    (tags: string[]) => {
      handleApplianceChange({
        tags: tags.map((tag) => tag.trim().toLowerCase()),
      });
    },
    [handleApplianceChange]
  );

  const handleApplianceTagCreate = useCallback(
    (tag: string) => {
      const tags = (applianceDraft.tags || []).concat(tag.trim().toLowerCase());
      handleApplianceChange({ tags });
    },
    [handleApplianceChange, applianceDraft.tags]
  );

  if (!applianceData.appliance || !appliance) {
    return (
      <>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.appliance.applianceNotFound.title"
              defaultMessage="Appliance not found."
              description="Page title for an appliance not found"
            />
          }
        >
          <Link route={Route.appliances}>
            <FormattedMessage
              id="pages.appliance.applianceNotFound.message"
              defaultMessage="Return to the appliance list"
              description="Page message for an appliance not found"
            />
          </Link>
        </Result.NotFound>
        <SidebarContent>
          <ApplianceSidebar />
        </SidebarContent>
      </>
    );
  }

  const tabKeys = externalApps
    .map((app) => app.id)
    .concat(["appliance-details"]);
  const defaultTabKey = tabKeys[0];

  return (
    <>
      <Alert
        show={!!updateErrorFeedback}
        variant="danger"
        onClose={() => setUpdateErrorFeedback(null)}
        dismissible
      >
        {updateErrorFeedback}
      </Alert>
      <Tabs tabsOrder={tabKeys} defaultActiveKey={defaultTabKey}>
        <Tab
          eventKey="appliance-details"
          title={intl.formatMessage({
            id: "pages.appliance.applianceDetails",
            defaultMessage: "Appliance Details",
          })}
          data-testid="tab-appliance-details"
          className="mt-3"
        >
          <Stack gap={4}>
            <Form>
              <Row>
                <Col>
                  <Card className="h-100 border-0 shadow-sm p-4">
                    <Stack gap={3}>
                      <h6 className="text-primary">
                        <FormattedMessage
                          id="pages.appliance.applianceIdentifiers"
                          defaultMessage="Appliance Identifiers"
                        />
                      </h6>
                      <Form.Group controlId="form-appliance-serial">
                        <Form.Label>
                          <FormattedMessage
                            id="Appliance.serialNumber"
                            defaultMessage="Appliance S/N"
                            description="Label for the appliance serial number"
                          />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={appliance.serial}
                          readOnly
                          plaintext
                        />
                      </Form.Group>
                      <Form.Group controlId="form-appliance-deviceId">
                        <Form.Label>
                          <FormattedMessage
                            id="Appliance.deviceId"
                            defaultMessage="Device ID"
                            description="Label for the Device ID of an appliance"
                          />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={appliance.device.deviceId}
                          readOnly
                          plaintext
                        />
                      </Form.Group>
                    </Stack>
                  </Card>
                </Col>
                <Col>
                  <Card className="h-100 border-0 shadow-sm p-4">
                    <Stack gap={3}>
                      <h6 className="text-primary">
                        <FormattedMessage
                          id="pages.appliance.applianceInfo"
                          defaultMessage="Appliance Info"
                        />
                      </h6>
                      <Form.Group controlId="form-appliance-name">
                        <Form.Label>
                          <FormattedMessage
                            id="Appliance.name"
                            defaultMessage="Name"
                            description="Label for the appliance name"
                          />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={applianceDraft.name}
                          readOnly={!canEditAppliances}
                          plaintext={!canEditAppliances}
                          onChange={(event) => {
                            handleApplianceChange({
                              name: event.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="form-appliance-tags">
                        <Form.Label>
                          <FormattedMessage
                            id="Appliance.tags"
                            defaultMessage="Tags"
                            description="Label for the appliance tags"
                          />
                        </Form.Label>
                        <MultiSelect
                          id="form-appliance-tags"
                          selected={applianceDraft.tags || []}
                          values={applianceDraft.tags || []}
                          getValueId={(tag) => tag}
                          getValueLabel={(tag) => tag}
                          onChange={handleApplianceTagsChange}
                          onCreateValue={handleApplianceTagCreate}
                          disabled={!canEditAppliances}
                        />
                      </Form.Group>
                      <ApplianceProvisioningStatus
                        deviceRef={appliance.device}
                      />
                    </Stack>
                  </Card>
                </Col>
              </Row>
            </Form>
            <ClientAssignee applianceRef={applianceData.appliance} />
          </Stack>
        </Tab>
        {externalApps.map((app) => (
          <Tab key={app.id} eventKey={app.id} title={app.displayName}>
            <RemoteApp
              appId={app.id}
              appUrl={app.sourceUrl}
              appProps={app.props}
            />
          </Tab>
        ))}
      </Tabs>
      <SidebarContent>
        <ApplianceSidebar appliance={appliance} />
      </SidebarContent>
    </>
  );
};

const Appliance = () => {
  const { applianceId = "" } = useParams();

  const [
    getApplianceQuery,
    getAppliance,
  ] = useQueryLoader<Appliance_getAppliance_Query>(GET_APPLIANCE_QUERY);

  useEffect(() => {
    getAppliance({ id: applianceId });
  }, [getAppliance, applianceId]);

  return (
    <Page>
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <ApplianceSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <ApplianceSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => {
            getAppliance({ id: applianceId });
          }}
        >
          {getApplianceQuery && (
            <ApplianceContent getApplianceQuery={getApplianceQuery} />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Appliance;
