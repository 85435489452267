import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import ReCAPTCHA from "react-google-recaptcha";

import type { Register_registerOrganization_Mutation } from "api/__generated__/Register_registerOrganization_Mutation.graphql";
import type { Register_login_Mutation } from "api/__generated__/Register_login_Mutation.graphql";
import { useSession } from "contexts/Session";
import { useTenantConfig } from "contexts/TenantConfig";
import { Link, Route, useNavigate } from "Navigation";
import AuthPage from "components/AuthPage";
import Button from "components/Button";
import InputPassword from "components/InputPassword";
import Spinner from "components/Spinner";
import Stack from "components/Stack";

const LOGIN_MUTATION = graphql`
  mutation Register_login_Mutation($input: LoginInput!) {
    login(input: $input) {
      token
    }
  }
`;

const REGISTER_ORGANIZATION_MUTATION = graphql`
  mutation Register_registerOrganization_Mutation(
    $input: RegisterOrganizationInput!
  ) {
    registerOrganization(input: $input) {
      organization {
        name
      }
      admin {
        name
        email
      }
    }
  }
`;

interface FormData {
  userName: string;
  userEmail: string;
  userPassword: string;
}

const initialFormData: FormData = {
  userName: "",
  userEmail: "",
  userPassword: "",
};

const Register = () => {
  const tenantConfig = useTenantConfig();
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [validated, setValidated] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(
    null
  );
  const intl = useIntl();
  const navigate = useNavigate();
  const { redirectTo, setAuthToken, setRedirectTo } = useSession();

  const [
    registerOrganization,
    isRegisteringOrganization,
  ] = useMutation<Register_registerOrganization_Mutation>(
    REGISTER_ORGANIZATION_MUTATION
  );

  const [login, isLoggingIn] = useMutation<Register_login_Mutation>(
    LOGIN_MUTATION
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        return setValidated(true);
      }
      const organization = { name: formData.userName };
      const adminUser = {
        name: formData.userName,
        credential: {
          email: formData.userEmail,
          password: formData.userPassword,
        },
      };
      registerOrganization({
        variables: { input: { organization, adminUser, recaptchaResponse } },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
          login({
            variables: {
              input: {
                email: formData.userEmail,
                password: formData.userPassword,
              },
            },
            onCompleted(data, errors) {
              if (errors) {
                const errorFeedback = errors
                  .map((error) => error.message)
                  .join(". \n");
                return setErrorFeedback(errorFeedback);
              }
              const authToken = data.login?.token || null;
              if (authToken) {
                // FIXME this ignores the "keep me logged in" feature
                setAuthToken(authToken);
                if (redirectTo) {
                  setRedirectTo(null);
                  navigate(redirectTo);
                }
              } else {
                navigate({ route: Route.login });
              }
            },
            onError(error) {
              navigate({ route: Route.login });
            },
          });
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.Register.form.loadingErrorFeedback"
              defaultMessage="Could not register, please try again."
              description="Feedback for unknown loading error in the Register page"
            />
          );
        },
      });
    },
    [
      formData,
      registerOrganization,
      login,
      setAuthToken,
      navigate,
      setRedirectTo,
      redirectTo,
      recaptchaResponse,
    ]
  );

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const field = target.id;
      setFormData((data) => ({ ...data, [field]: value }));
    },
    []
  );

  const isRegistering = isRegisteringOrganization || isLoggingIn;
  const showCaptcha = !!tenantConfig.authConfig.recaptchaSiteKey;
  const isMissingCaptcha = showCaptcha && !recaptchaResponse;
  const canRegister = !isMissingCaptcha && !isRegistering;

  return (
    <AuthPage>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Stack gap={3}>
          <Form.Group controlId="userName">
            <Form.Control
              value={formData.userName}
              onChange={handleInputChange}
              required
              placeholder={intl.formatMessage({
                id: "pages.Register.form.userNamePlaceholder",
                defaultMessage: "Name",
                description:
                  "Placeholder for the user name field in the Register page",
              })}
            />
            <Form.Control.Feedback type="invalid">
              <FormattedMessage
                id="pages.Register.form.invalidUserNameFeedback"
                defaultMessage="Please provide a user name."
                description="Feedback for invalid user name field in the Register page"
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="userEmail">
            <Form.Control
              value={formData.userEmail}
              onChange={handleInputChange}
              required
              type="email"
              placeholder={intl.formatMessage({
                id: "pages.Register.form.userEmailPlaceholder",
                defaultMessage: "Email",
                description:
                  "Placeholder for the user email field in the Register page",
              })}
            />
            <Form.Control.Feedback type="invalid">
              <FormattedMessage
                id="pages.Register.form.invalidUserEmailFeedback"
                defaultMessage="Please provide a valid user email."
                description="Feedback for invalid user email field in the Register page"
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="userPassword">
            <InputPassword
              value={formData.userPassword}
              onChange={handleInputChange}
              required
              placeholder={intl.formatMessage({
                id: "pages.Register.form.userPasswordPlaceholder",
                defaultMessage: "Password",
                description:
                  "Placeholder for the user password field in the Register page",
              })}
            >
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.Register.form.invalidUserPasswordFeedback"
                  defaultMessage="Please provide a password."
                  description="Feedback for invalid user password field in the Register page"
                />
              </Form.Control.Feedback>
            </InputPassword>
          </Form.Group>
          {tenantConfig.authConfig.recaptchaSiteKey && (
            <div className="d-flex justify-content-center">
              <ReCAPTCHA
                sitekey={tenantConfig.authConfig.recaptchaSiteKey}
                onChange={setRecaptchaResponse}
              />
            </div>
          )}
          <Button
            variant="primary"
            type="submit"
            className="w-100 mt-3"
            disabled={!canRegister}
          >
            {isRegistering && <Spinner size="sm" className="me-2" />}
            <FormattedMessage
              id="pages.Register.form.passwordRegisterButton"
              defaultMessage="Register"
              description="Title for the button to register with password in the Register Page"
            />
          </Button>
        </Stack>
        <div className="mt-5 text-center">
          <p>
            <FormattedMessage
              id="pages.Register.form.loginLink"
              defaultMessage="Already have an account? <loginLink>Login</loginLink>"
              description="Title for the link to Login in the Register Page"
              values={{
                loginLink: (chunks: React.ReactNode) => (
                  <Link route={Route.login}>{chunks}</Link>
                ),
              }}
            />
          </p>
        </div>
      </Form>
    </AuthPage>
  );
};

export default Register;
