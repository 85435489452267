import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { FormattedMessage } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";

import * as images from "assets/images";
import type { Client_getOrganization_Query } from "api/__generated__/Client_getOrganization_Query.graphql";
import type { Client_getAppliances_Query } from "api/__generated__/Client_getAppliances_Query.graphql";
import type { Client_removeClient_Mutation } from "api/__generated__/Client_removeClient_Mutation.graphql";
import { Link, Route, useNavigate } from "Navigation";
import AppliancesTable from "components/AppliancesTable";
import Button from "components/Button";
import Can from "components/Can";
import Center from "components/Center";
import DeleteModal from "components/DeleteModal";
import Image from "components/Image";
import Page, {
  PageLoading,
  PageLoadingError,
  PageToolbar,
} from "components/Page";
import Result from "components/Result";
import SearchBox from "components/SearchBox";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";

const GET_APPLIANCES_QUERY = graphql`
  query Client_getAppliances_Query {
    appliances {
      id
      name
      serial
      device {
        lastConnection
        lastDisconnection
        online
      }
      assignee {
        id
        name
      }
    }
  }
`;

const GET_ORGANIZATION_QUERY = graphql`
  query Client_getOrganization_Query($id: ID!) {
    organization(id: $id) {
      name
    }
  }
`;

const REMOVE_CLIENT_MUTATION = graphql`
  mutation Client_removeClient_Mutation($input: RemoveClientInput!) {
    removeClient(input: $input) {
      client {
        id
      }
    }
  }
`;

type ClientSidebarProps = {
  client?: Client_getOrganization_Query["response"]["organization"];
  onDelete?: () => void;
};

const ClientSidebar = ({ client, onDelete }: ClientSidebarProps) => {
  if (!client) {
    return (
      <Stack gap={3} className="mt-3 p-3">
        <Image src={images.clients} />
      </Stack>
    );
  }
  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      <h4>{client.name}</h4>
      <Image fallbackSrc={images.clients} />
      <Can oneOf={["CAN_REMOVE_CLIENTS"]}>
        <hr />
        <Center>
          <Button variant="danger" onClick={onDelete}>
            <FormattedMessage
              id="pages.Client.deleteClientButton"
              defaultMessage="Delete Client"
            />
          </Button>
        </Center>
      </Can>
    </Stack>
  );
};

interface ClientContentProps {
  getAppliancesQuery: PreloadedQuery<Client_getAppliances_Query>;
  getOrganizationQuery: PreloadedQuery<Client_getOrganization_Query>;
}

const ClientContent = ({
  getAppliancesQuery,
  getOrganizationQuery,
}: ClientContentProps) => {
  const { clientId = "" } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const clientData = usePreloadedQuery(
    GET_ORGANIZATION_QUERY,
    getOrganizationQuery
  );
  const appliancesData = usePreloadedQuery(
    GET_APPLIANCES_QUERY,
    getAppliancesQuery
  );

  // TODO: handle readonly type without mapping to mutable type
  const appliances = useMemo(
    () =>
      appliancesData.appliances
        .filter(
          (appliance) =>
            appliance.assignee != null && appliance.assignee.id === clientId
        )
        .map((appliance) => ({
          id: appliance.id,
          name: appliance.name,
          serial: appliance.serial,
          assignee: appliance.assignee && {
            id: appliance.assignee.id,
            name: appliance.assignee.name,
          },
          device: {
            ...appliance.device,
          },
          tags: [], // TODO: fetch from backend
        })),
    [appliancesData.appliances, clientId]
  );

  const [
    removeClient,
    isRemovingClient,
  ] = useMutation<Client_removeClient_Mutation>(REMOVE_CLIENT_MUTATION);

  const handleDeleteClient = useCallback(() => {
    removeClient({
      variables: { input: { clientId } },
      onCompleted(data, errors) {
        if (errors) {
          setShowDeleteModal(false);
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(errorFeedback);
        }
        navigate({ route: Route.clients });
      },
      onError(error) {
        setShowDeleteModal(false);
        setErrorFeedback(
          <FormattedMessage
            id="pages.Client.form.deleteClientErrorFeedback"
            defaultMessage="Could not delete client, please try again."
            description="Feedback for unknown error while deleting a client"
          />
        );
      },
      updater(store) {
        // TODO: should use and update Connections instead of invalidating the entire store
        // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
        store.invalidateStore();
      },
    });
  }, [clientId, removeClient, navigate]);

  const client = clientData.organization;

  if (!client) {
    return (
      <>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.client.clientNotFound.title"
              defaultMessage="Client not found."
              description="Page title for a client not found"
            />
          }
        >
          <Link route={Route.clients}>
            <FormattedMessage
              id="pages.client.clientNotFound.message"
              defaultMessage="Return to the client list"
              description="Page message for a client not found"
            />
          </Link>
        </Result.NotFound>
        <SidebarContent>
          <ClientSidebar />
        </SidebarContent>
      </>
    );
  }

  return (
    <>
      <PageToolbar>
        <SearchBox value={searchText} onChange={setSearchText} />
      </PageToolbar>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <AppliancesTable data={appliances} hideAssignee searchText={searchText} />
      <SidebarContent>
        <ClientSidebar
          client={client}
          onDelete={() => setShowDeleteModal(true)}
        />
      </SidebarContent>
      {showDeleteModal && (
        <DeleteModal
          confirmText={client?.name || "ok"}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteClient}
          isDeleting={isRemovingClient}
          title={
            <FormattedMessage
              id="pages.Client.deleteModal.title"
              defaultMessage="Delete Client"
              description="Title for the confirmation modal to delete a client"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.Client.deleteModal.description"
              defaultMessage="This action cannot be undone. This will permanently delete the client <bold>{client}</bold>."
              description="Description for the confirmation modal to delete a client"
              values={{
                client: client?.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </>
  );
};

const Client = () => {
  const { clientId = "" } = useParams();
  const [
    getOrganizationQuery,
    getOrganization,
  ] = useQueryLoader<Client_getOrganization_Query>(GET_ORGANIZATION_QUERY);
  const [
    getAppliancesQuery,
    getAppliances,
  ] = useQueryLoader<Client_getAppliances_Query>(GET_APPLIANCES_QUERY);

  useEffect(() => {
    getAppliances({});
    getOrganization({ id: clientId });
  }, [getAppliances, getOrganization, clientId]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.Client.title"
          defaultMessage="Client Appliances"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <ClientSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <ClientSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => {
            getAppliances({});
            getOrganization({ id: clientId });
          }}
        >
          {getAppliancesQuery && getOrganizationQuery && (
            <ClientContent
              getAppliancesQuery={getAppliancesQuery}
              getOrganizationQuery={getOrganizationQuery}
            />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Client;
