import React, {
  createContext,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import graphql from "babel-plugin-relay/macro";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { ErrorBoundary } from "react-error-boundary";

import type {
  Viewer_getViewerPermissions_Query,
  Viewer_getViewerPermissions_Query$data,
} from "api/__generated__/Viewer_getViewerPermissions_Query.graphql";
import type Permission from "api/Permission";
import { useSession } from "contexts/Session";
import Spinner from "components/Spinner";

const GET_VIEWER_PERMISSIONS_QUERY = graphql`
  query Viewer_getViewerPermissions_Query {
    viewer {
      id
      permissions
      organization {
        thin
      }
    }
  }
`;

type Viewer = Viewer_getViewerPermissions_Query$data["viewer"];

const QueryError = ({ errorCallback }: { errorCallback: () => void }) => {
  errorCallback();
  return <React.Fragment />;
};

type ViewerQueryProps = {
  onQueryFinished: (viewer: Viewer) => void;
};

const ViewerQuery = ({ onQueryFinished }: ViewerQueryProps) => {
  const [
    getViewerPermissionsQuery,
    getViewerPermissions,
  ] = useQueryLoader<Viewer_getViewerPermissions_Query>(
    GET_VIEWER_PERMISSIONS_QUERY
  );

  useEffect(() => {
    getViewerPermissions({}, { fetchPolicy: "network-only" });
  }, [getViewerPermissions]);

  if (!getViewerPermissionsQuery) {
    return null;
  }

  return (
    <ErrorBoundary
      fallback={<QueryError errorCallback={() => onQueryFinished(null)} />}
    >
      <Suspense
        fallback={
          <div data-testid="viewer-context-loading" className="d-none" />
        }
      >
        <ViewerData
          getViewerPermissionsQuery={getViewerPermissionsQuery}
          onQueryFinished={onQueryFinished}
        />
      </Suspense>
    </ErrorBoundary>
  );
};

type ViewerDataProps = {
  getViewerPermissionsQuery: PreloadedQuery<Viewer_getViewerPermissions_Query>;
  onQueryFinished: (viewer: Viewer) => void;
};

const ViewerData = ({
  getViewerPermissionsQuery,
  onQueryFinished,
}: ViewerDataProps) => {
  const viewerPermissionsData = usePreloadedQuery<Viewer_getViewerPermissions_Query>(
    GET_VIEWER_PERMISSIONS_QUERY,
    getViewerPermissionsQuery
  );

  useEffect(() => {
    onQueryFinished(viewerPermissionsData?.viewer);
  }, [onQueryFinished, viewerPermissionsData]);

  return null;
};

type ViewerContextValue = {
  readonly isAuthenticated: boolean;
  readonly isThinOrganization: boolean;
  readonly userId: string | null;
  readonly userPermissions: Readonly<Permission[]>;
};

const ViewerContext = createContext<ViewerContextValue | null>(null);

interface ViewerProviderProps {
  children: React.ReactNode;
}

const ViewerProvider = ({ children }: ViewerProviderProps) => {
  const { authToken, setAuthToken } = useSession();
  const [initalTokenValidation, setInitalTokenValidation] = useState(
    !!authToken
  );
  // TODO for a smoother UX, the login mutation should return the viewer node
  const [isGettingViewer, setIsGettingViewer] = useState(!!authToken);
  const [viewer, setViewer] = useState<Viewer>(null);

  useEffect(() => {
    setIsGettingViewer(!!authToken);
    setViewer(null);
  }, [authToken]);

  const handleViewerQuery = useCallback(
    (result: Viewer) => {
      if (result) {
        setViewer(result);
      } else {
        setAuthToken(null);
      }
      setInitalTokenValidation(false);
      setIsGettingViewer(false);
    },
    [setAuthToken]
  );

  const contextValue: ViewerContextValue = useMemo(
    () => ({
      isAuthenticated: !!viewer,
      isThinOrganization: viewer ? viewer.organization.thin : true,
      userId: viewer ? viewer.id : null,
      userPermissions: viewer ? viewer.permissions : [],
    }),
    [viewer]
  );

  return (
    <ViewerContext.Provider value={contextValue}>
      {isGettingViewer && <ViewerQuery onQueryFinished={handleViewerQuery} />}
      {initalTokenValidation ? (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        children
      )}
    </ViewerContext.Provider>
  );
};

const useViewer = (): ViewerContextValue => {
  const contextValue = useContext(ViewerContext);
  if (contextValue == null) {
    throw new Error("AuthContext has not been Provided");
  }
  return contextValue;
};

export { useViewer, ViewerContext };

export default ViewerProvider;
