import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { usePrompt, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import _ from "lodash";

import * as images from "assets/images";
import type { User_getRoles_Query } from "api/__generated__/User_getRoles_Query.graphql";
import type { User_getUser_Query } from "api/__generated__/User_getUser_Query.graphql";
import type { User_updateUserRoles_Mutation } from "api/__generated__/User_updateUserRoles_Mutation.graphql";
import type { User_deleteUser_Mutation } from "api/__generated__/User_deleteUser_Mutation.graphql";
import { Link, Route, useNavigate } from "Navigation";
import Button from "components/Button";
import Can, { useCanOneOf } from "components/Can";
import Center from "components/Center";
import DeleteModal from "components/DeleteModal";
import Image from "components/Image";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import Result from "components/Result";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import Tag from "components/Tag";
import UserForm from "components/UserForm";
import { useViewer } from "contexts/Viewer";

type UserDraft = {
  email: string;
  name: string;
  roles: {
    id: string;
    name: string;
  }[];
};

const GET_ROLES_QUERY = graphql`
  query User_getRoles_Query {
    roles {
      id
      name
    }
  }
`;

const GET_USER_QUERY = graphql`
  query User_getUser_Query($id: ID!) {
    user(id: $id) {
      id
      email
      name
      roles {
        id
        name
      }
    }
  }
`;

const UPDATE_USER_ROLES_MUTATION = graphql`
  mutation User_updateUserRoles_Mutation($input: UpdateUserRolesInput!) {
    updateUserRoles(input: $input) {
      user {
        id
        roles {
          id
          name
        }
      }
    }
  }
`;

const DELETE_USER_MUTATION = graphql`
  mutation User_deleteUser_Mutation($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        id
      }
    }
  }
`;

type UserSidebarProps = {
  user?: User_getUser_Query["response"]["user"];
  onDelete?: () => void;
};

const UserSidebar = ({ user, onDelete }: UserSidebarProps) => {
  const { userId: viewerId } = useViewer();
  const canDeleteUsers = useCanOneOf(["CAN_DELETE_USERS"]);
  const canDeleteUser = canDeleteUsers && user != null && user.id !== viewerId;

  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      {user && (
        <div>
          <h4>{user.name}</h4>
          <p className="text-muted">{user.email}</p>
        </div>
      )}
      <Image fallbackSrc={images.profile} />
      {user && (
        <div>
          {user.roles.map((role) => (
            <Tag className="mb-1 me-1" key={role.id}>
              {role.name}
            </Tag>
          ))}
        </div>
      )}
      {canDeleteUser && (
        <>
          <hr />
          <Center>
            <Button variant="danger" onClick={onDelete}>
              <FormattedMessage
                id="pages.User.deleteUserButton"
                defaultMessage="Delete User"
              />
            </Button>
          </Center>
        </>
      )}
    </Stack>
  );
};

interface UserContentProps {
  getRolesQuery: PreloadedQuery<User_getRoles_Query>;
  getUserQuery: PreloadedQuery<User_getUser_Query>;
}

const UserContent = ({ getRolesQuery, getUserQuery }: UserContentProps) => {
  const { userId = "" } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const rolesData = usePreloadedQuery(GET_ROLES_QUERY, getRolesQuery);
  const userData = usePreloadedQuery(GET_USER_QUERY, getUserQuery);
  const [
    updateUserRoles,
    isUpdatingUserRoles,
  ] = useMutation<User_updateUserRoles_Mutation>(UPDATE_USER_ROLES_MUTATION);
  const [deleteUser, isDeletingUser] = useMutation<User_deleteUser_Mutation>(
    DELETE_USER_MUTATION
  );

  // TODO: handle readonly roles type without mapping to mutable type
  const roles = rolesData.roles.map((role) => _.pick(role, ["id", "name"]));
  const user = useMemo(() => userData.user && { ...userData.user }, [
    userData.user,
  ]);

  // TODO: handle readonly user type without mapping to mutable draft.user type
  const [draft, setDraft] = useState({
    user: {
      email: user?.email || "",
      name: user?.name || "",
      roles:
        user?.roles.map((role) => ({ id: role.id, name: role.name })) || [],
    },
    isValid: true,
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  usePrompt(
    intl.formatMessage({
      id: "pages.User.leavingWithUnsavedChangesFeedback",
      defaultMessage:
        "Are you sure you want to leave? Changes you entered may not be saved.",
      description:
        "Prompt feedback shown when the user leaves the User form with unsaved changes",
    }),
    hasUnsavedChanges
  );

  const handleFormChange = useCallback(
    (user: UserDraft, isValid: boolean) => {
      setDraft({ user, isValid });
      setHasUnsavedChanges(true);
      if (isValid) {
        const roleIds = user.roles.map((role) => role.id);
        updateUserRoles({
          variables: { input: { userId, roleIds } },
          onCompleted(data, errors) {
            if (errors) {
              setDraft({ user: draft.user, isValid: true });
            }
            setHasUnsavedChanges(false);
          },
          onError(error) {
            setDraft({ user: draft.user, isValid: true });
            setHasUnsavedChanges(false);
          },
          updater(store) {
            // TODO: should use and update Connections instead of invalidating the entire store
            // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
            store.invalidateStore();
          },
        });
      }
    },
    [updateUserRoles, userId, draft.user]
  );

  const handleDeleteUser = useCallback(() => {
    deleteUser({
      variables: { input: { userId } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setShowDeleteModal(false);
          return setErrorFeedback(errorFeedback);
        }
        navigate({ route: Route.users });
      },
      onError() {
        setErrorFeedback(
          <FormattedMessage
            id="pages.User.deleteErrorFeedback"
            defaultMessage="Could not delete the user, please try again."
            description="Feedback for unknown deletion error in the User page"
          />
        );
        setShowDeleteModal(false);
      },
      updater(store, data) {
        const userId = data?.deleteUser?.user.id;
        if (userId) {
          const root = store.getRoot();
          const users = root.getLinkedRecords("users");
          if (users) {
            root.setLinkedRecords(
              users.filter((user) => user.getDataID() !== userId),
              "users"
            );
          }
        }
      },
    });
  }, [deleteUser, userId, navigate]);

  if (!user) {
    return (
      <>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.user.userNotFound.title"
              defaultMessage="User not found."
              description="Page title for a user not found"
            />
          }
        >
          <Link route={Route.users}>
            <FormattedMessage
              id="pages.user.userNotFound.message"
              defaultMessage="Return to the user list"
              description="Page message for a user not found"
            />
          </Link>
        </Result.NotFound>
        <SidebarContent>
          <UserSidebar />
        </SidebarContent>
      </>
    );
  }

  return (
    <>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <Can oneOf={["CAN_MANAGE_USER_ROLES"]}>
        {(canManageUserRoles) => (
          <UserForm
            roles={roles}
            value={draft.user}
            onChange={handleFormChange}
            isLoadingRoles={isUpdatingUserRoles}
            canManageUserRoles={canManageUserRoles}
          />
        )}
      </Can>
      <SidebarContent>
        <UserSidebar user={user} onDelete={() => setShowDeleteModal(true)} />
      </SidebarContent>
      {showDeleteModal && (
        <DeleteModal
          confirmText={user.email}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteUser}
          isDeleting={isDeletingUser}
          title={
            <FormattedMessage
              id="pages.User.deleteModal.title"
              defaultMessage="Delete User from Organization"
              description="Title for the confirmation modal to delete a user from an organization"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.User.deleteModal.description"
              defaultMessage="The user <bold>{user}</bold> will be deleted."
              description="Description for the confirmation modal to delete a user from an organization"
              values={{
                user: user.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </>
  );
};

const User = () => {
  const { userId = "" } = useParams();

  const [getRolesQuery, getRoles] = useQueryLoader<User_getRoles_Query>(
    GET_ROLES_QUERY
  );

  const [getUserQuery, getUser] = useQueryLoader<User_getUser_Query>(
    GET_USER_QUERY
  );

  useEffect(() => {
    getRoles({});
    getUser({ id: userId });
  }, [getRoles, getUser, userId]);

  return (
    <Page
      title={
        <FormattedMessage id="pages.User.title" defaultMessage="User Details" />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <UserSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <UserSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => getUser({ id: userId })}
        >
          {getRolesQuery && getUserQuery && (
            <UserContent
              getRolesQuery={getRolesQuery}
              getUserQuery={getUserQuery}
            />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default User;
